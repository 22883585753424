.footer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60%;
    padding: 51px 323px 40px 323px;
    background-color: #D4EBFF;
    align-items: center;

}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-title {
    width: 587px;
    color: var(--Grey-Grey-900, #212121);
    text-align: center;

    /* LG/H1 */
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 48px */

    margin-bottom: 36px;
}

.email-input-form {
    display: flex;
    padding: 8px 8px 8px 16px;
    align-items: center;
    gap: 28px;
    border-radius: 8px;
    background: var(--Grey-White, #FFF);
}

.email-input-box {
    width: 500px;
    border: none;
    outline: none;
    background-color: transparent;
}

.email-input-button {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    color: #212121;
}

.email-input-button:hover {
    filter: brightness(90%);
    cursor: pointer;
}

.footer-disclaimer {
    width: 588px;
    color: var(--Grey-Grey-700, #616161);

    /* Paragraph/Body2-R */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    /* 24.5px */

    margin-bottom: 24px;
}

.social-media-icons {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 24px;
    margin-bottom: 82px;
}

.social-media-icon {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 100px;
}

.social-media-icon:hover {
    filter: brightness(90%);
    cursor: pointer;
}

.footer-copyright {
    color: var(--Grey-Grey-600, #757575);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */

}


@media screen and (max-width: 790px) {

    .footer-container {
        padding: 51px 12px 40px 12px;
    }

    .email-input-box {
        width: 450px;

    }
}

@media screen and (max-width: 700px) {

    .footer-title {
        width: 400px;
    }


    .email-input-box {
        width: 300px;

    }

    .footer-disclaimer {
        width: 400px;
    }

}

@media screen and (max-width: 500px) {

    .email-input-box {
        width: 250px;
    }

    .email-input-button {
        width: 90px;
        gap: 5px;
        font-size: 12px;
        color: #212121;
    };

    .footer-disclaimer {
        width: 150px;
    }
    
}

@media screen and (max-width: 376px) {

    .footer-title {
        width: 300px;
    }


    .email-input-box {
        width: 150px;

    }

    .footer-disclaimer {
        width: 200px;
    }
}