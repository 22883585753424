.feedback-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 57px 54px 57px 40px;
    background-color: #FFF;
    justify-content: flex-start;


}

.feedback-title {
    color: var(--Grey-Grey-900, #212121);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 132%;
    /* 42.24px */

}

.feedback-subtitle {
    margin-top: 24px;
    align-self: center;
    color: var(--Grey-Grey-900, #212121);
    text-align: center;
    width: 619px;
    /* LG/Subtitle */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
    /* 29.7px */
}

.feedback-card {
    height: 370px;
    display: inline-flex;
    padding: 24px 32px 16px 32px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: var(--Grey-White, #FFF);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
}

.feedback-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.feedback-card-profile {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--Grey-Grey-300, #D9D9D9);
}

.feedback-card-profile-picture {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.feedback-card-profile-name {
    width: 168px;
    height: 24px;
    color: var(--Grey-Grey-900, #212121);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%;
    /* 24.5px */
}

.feedback-card-text {
    width: 218px;
    height: 148px;
    color: var(--Grey-Grey-900, #212121);

    /* Paragraph/Caption2-R */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    /* 21px */
}

.feedback-card-review {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 10px;
}

.star-reviews {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.feedback-slider-container {
    width: 100%;
    margin-top: 55px;
}


.feedback-slider-container .slick-prev:before,
.feedback-slider-container .slick-next:before {
    color: black;
}

.feedback-slider-container .slick-track {
    display: flex;
    gap: 56px;
    margin-top: 10px;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
    align-self: center;
}


@media screen and (max-width: 1300px) {

    .feedback-container {
        height: 100%;
        padding: 57px 54px 60px 40px;
    }

    .feedback-card {
        height: 370px;
        display: inline-flex;
        padding: 16px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        background: var(--Grey-White, #FFF);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
    }


}

@media screen and (max-width: 1000px) {
    .feedback-container {
        padding: 30px;
    }

    .feedback-cards {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

}


@media screen and (max-width: 768px) {
    .feedback-container {
        padding: 30px;
    }

    .feedback-subtitle {
        width: 90%;
    
    }


}

@media screen and (max-width: 500px) {

    .feedback-subtitle {
        width: 90%;
    }

}


@media screen and (max-width: 376px) {

    .feedback-container {
        padding: 30px;
    }

    .feedback-subtitle {
        width: 90%;
    }

    .feedback-slider-container .slick-slide {
        width: 100%;
    }

}