.write-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 50px 24px 50px 44px;
    background-color: #F0F8FF;
    
    overflow: hidden;

}



.write-card-content {
    display: flex;
    width: 100%;
    gap: 61px;
    flex-direction: row;
    overflow: hidden;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: 1fr;

}

.write-card-grid-right {
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: flex-start;
    justify-content: space-around;
    background-image: url('./../../../../public/images/WriteCard-background.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.write-card-text {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.write-card-title {
    width: 420px;
    color: var(--Grey-Grey-900, #212121);
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    /* 48px */
}

.write-card-info {
    color: var(--Grey-Grey-900, #212121);

    width: 420px;
    height: 149px;
    /* LG/Subtitle */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
    /* 29.7px */
}

.write-card-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* Preserve aspect ratio */
}

.write-card-button {
    display: flex;
    width: 168px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    background: white;
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
}

.write-card-button:hover {
    filter: brightness(0.9);
    cursor: pointer;
}

.write-card-grid-left {
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;

}


@media screen and (max-width: 1300px) {

    .write-card-container {
        padding: 50px 12px 50px 12px;
    }
    

    .write-card-grid-left {
        width: 50%;
        align-items: center;
    }

    .write-card-grid-right {
        align-items: center;
    }
    
}

@media screen and (max-width: 1000px) {

    .write-card-container {
        padding: 50px 12px 50px 12px;
    }

    .write-card-content {
        flex-direction: column-reverse;
        gap: 0px;
        align-items: center;
    }
    
    .write-card-grid-left {
        width: 100%;
        align-items: center;
    }

    .write-card-grid-right {
        width: 100%;
        align-items: center;
    }

}


@media screen and (max-width: 790px) {

    .write-card-container {
        padding: 50px 12px 50px 12px;
    }

    .write-card-content {
        flex-direction: column-reverse;
        gap: 0px;
        align-items: center;
    }
    
    .write-card-grid-left {
        width: 100%;
        align-items: center;
    }

    .write-card-grid-right {
        width: 100%;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {

    .write-card-container {
        background-size: cover;
        background-position: bottom right;
    }

    .write-card-content {
        gap: 50px;
    }

    .write-card-text {
       align-items: center;
       gap: 30px;
    }
    .write-card-title {
        text-align: center;
        width: 90%;
    }
    .write-card-info {
        text-align: center;
        width: 90%;
    }

    
    
}