

.title {
    width: 587px;
    color: var(--Grey-Grey-900, #212121);
    text-align: center;

    /* LG/H1 */
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 48px */

    margin-bottom: 36px;
}

.footer-disclaimer {
    width: 588px;
    color: var(--Grey-Grey-700, #616161);

    /* Paragraph/Body2-R */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    /* 24.5px */

    margin-bottom: 24px;
}

.close-button {
    float: right;
    cursor: pointer;
}

.email-input-form {
    display: flex;
    padding: 8px 8px 8px 16px;
    align-items: center;
    gap: 28px;
    border-radius: 8px;
    background: var(--Grey-White, #FFF);
}

.email-input-box {
    max-width: 450px;
    border: none;
    outline: none;
    background: transparent;
}

.email-input-button {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    background: #FFF;
}

.status-message {
    text-align: center;
}





/* Overlay for the modal background */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

/* Modal container styling */
.modal-container {
    
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1050;
    transform: translate(-50%, -50%);
    background-color: #D4EBFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: var(--spacing-medium);
   
    transition: transform 0.3s ease;
    width: 700px;
    
}

.modal-content {
   
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.modal-container.show {
    transform: translate(-50%, -50%) scale(1.05);
}

/* Modal header with close button */
.modal-header {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

/* Modal title */
.modal-title {
    font-size: 1.5em;
    font-weight: bold;
}

/* Modal body for content */
.modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    color: #212121;
}

.close-button {
    cursor: pointer;
    background: none;
    border: none;
    float: right;
}



/* Close button in the modal footer */
.modal-footer .btn-secondary {
    padding: 5px 10px;
    background-color: #ccc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Close button hover effect */
.modal-footer .btn-secondary:hover {
    background-color: #bbb;
}

/* Adding custom transition effects to the modal */
.modal-container,
.modal-backdrop {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

/* Visible state of the modal */
.modal-container.show,
.modal-backdrop.show {
    opacity: 1;
    pointer-events: auto;
}




/* Add padding within the modal body to ensure space around the buttons */
.modal-body {
    padding: 1rem; /* 16px padding */
}

/* Adjust the modal footer to be less prominent if desired */
.modal-footer {
    padding-top: 0.5rem; /* Reduce padding above the close button */
    padding-bottom: 0.5rem; /* Reduce padding below the close button */
}


@media screen and (max-width: 900px) {
    
    .modal-container {
        top: 50%;
        left: 50%;
        width: 80%;
    }

    .email-input-box {
        max-width: 400px;
    }
    
}


@media screen and (max-width: 790px) {


    .email-input-box {
        max-width: 300px;
    }
    

    .footer-disclaimer {
        max-width: 400px;
    }
}

@media screen and (max-width: 640px)  {

    .modal-container {
        width: 75%;
    }

    .email-input-box {
        max-width: 200px;
    }

    .email-input-button {
        max-width: 100px;
        gap: 5px;
        font-size: 12px;
        color: #212121;
    }

    .footer-disclaimer {
        max-width: 300px;
    }
    
}


@media screen and (max-width: 500px)  {

    .modal-container {
        width: 80%;
    }

    .email-input-box {
        max-width: 180px;
    }

    .email-input-button {
        padding: 5px;
        max-width: 75px;
        gap: 0px;
        font-size: 12px;
    }

    .footer-disclaimer {
        max-width: 200px;
    }
    
}

@media screen and (max-width: 440px)  {

    .modal-container {
        width: 80%;
    }

    .email-input-box {
        max-width: 150px;
    }

    .email-input-button {
        padding: 5px;
        max-width: 75px;
        gap: 0px;
        font-size: 12px;
    }

    .footer-disclaimer {
        max-width: 200px;
    }
    
}

