.header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 44px 24px 0px 44px;
    background-color: #2A4FE7;
    background-image: url('./../../../../public/images/Header-background.svg');
    background-size: cover;

}

.header-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 70px;
}

.header-content {
    display: flex;
    width: 100%;
    margin-left: 44px;
    gap: 61px;
    flex-direction: row;
    overflow: hidden;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: 1fr;

}

.header-content-title {
    color: white;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 132%;
    margin-top: 1.5rem;
    /* 63.36px */
}

.header-content-text {
    display: flex;
    width: 417px;
    height: 51px;
    flex-shrink: 0;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    margin-bottom: 3rem;
    /* 26.4px */
}

.header-grid-left {
    display: flex;
    flex-direction: column;
    
    width: 35%;
    align-items: flex-start;
    
}

.header-image {
    width: 95%;
    height: 95%;
    object-fit: contain;
    /* Preserve aspect ratio */
}

.header-button {
    display: flex;
    width: 168px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    background: white;
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border: none;
    /* 24px */
}

.header-button:hover {
    filter: brightness(0.9);
    cursor: pointer;
}

.header-grid-right {
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;

}


@media screen and (max-width: 1300px) {

    .header-container {
        padding: 50px 12px 50px 12px;
    }

    .header-top {
        margin-bottom: 30px;
    }

    .header-content {
        
        gap: 0px;
    }

    .header-grid-left {
        width: 50%;

    }

}

@media screen and (max-width: 1000px) {

    .header-content {
        flex-direction: column;
        align-items: center;
        gap: 56px;
        text-align: center;
        margin-left: 0px;
    }

    .header-grid-right {
        width: 100%;
        align-items: center;
    }

    .header-grid-left {
        width: 100%;
        align-items: center;
    }






}


@media screen and (max-width: 790px) {

    .header-container {
        padding: 50px 12px 50px 12px;
    }



    .header-grid-left {
        width: 100%;
        align-items: center;
    }

    .header-grid-right {
        width: 100%;
        align-items: center;
    }
}

@media screen and (max-width: 630px) {

    .header-top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header-content-title {
        font-size: 36px;
    }

    .header-content-text {
        width: 90%;
    }

   

}