.products-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 57px 90px 30px 90px;
    background-color: #FFF;
    justify-content: space-evenly
}

.products-cards {
    display: inline-flex;
    gap: 56px;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 71px;
   
}

.product-card {
    display: flex;
    padding: 28px 28px 36px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.10);
    align-items: center;
    width: 400px;
}

.product-card-title {
    flex: 1 0 0;
    color: var(--Grey-Grey-900, #212121);
    text-align: center;
    /* LG/H3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 32px */
}

.product-card-text {
    flex: 1 0 0;
    color: var(--Grey-Grey-700, #616161);
    text-align: center;
    /* Paragraph/Body1-R */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    /* 26.4px */
}

.university-section {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

}

.university-section-title {
    color: var(--Grey-Grey-500, #9E9E9E);
    text-align: center;

    /* LG/H3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 32px */
}



.image-slider-container {
    margin-bottom: 10px;
    width: 100%;

}

.image-slider-container .slick-prev:before,
.image-slider-container .slick-next:before {
    color: black;
}

.image-slider-container .slick-track {
    display: flex;
    gap: 56px;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
    align-self: center;
}


@media screen and (max-width: 1300px) {


    .image-slider-container {
        margin-top: 0px;
        width: 100%;

    }

    .products-container {
        padding: 57px 90px 75px 90px;
    }

    .products-cards {
        display: inline-flex;
        gap: 56px;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 30px;
    }

    .product-card {
        width: 100%;
    }
    
}

@media screen and (max-width: 1000px) {
    .products-container {
        
        padding: 30px;
    }

    .products-cards {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

}


@media screen and (max-width: 768px) {
    .products-container {
        padding: 30px;
    }

    .products-cards {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }

    .product-card {
        padding: 20px;
    }

    .university-section {
        gap: 10px;
    }

    .image-slider-container .slick-track {
        gap: 30px;
    }
}