.back-to-top-button-container {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    right: 30px;
    z-index: 10000;
}

.back-to-top-button {
    display: flex;
    justify-self: center;
    cursor: pointer;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    color: #fff;
    font-size: 15px;
    background-color: #046bd2;
    border: none;
}

.back-to-top-button:hover {
    filter: brightness(0.9);
}